import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Box, Typography, useMediaQuery } from '@mui/material';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const Container = styled(Box)`
  text-align: center;
  padding: 50px;
`;

// Custom styled components
const UnderlinedText = styled('span')`
  font-weight: 900;
`;

const TextColor = styled(Typography)`
  color: #4f46e5;
  font-weight: bold;
`;


function App() {
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    AOS.init({
      duration: 500, // Animation duration in milliseconds
    });
  }, []);

  return (
    <Container>
      <Box textAlign="center">
      <Typography variant={isMobile ? "h2" : "h1"} data-aos="fade-down" component="div" sx={{ textAlign: 'center', marginTop: '32px', fontWeight: '700' }}>
          Hey I'm <UnderlinedText>Jay</UnderlinedText>
        </Typography>
        <Typography variant="h5" data-aos="fade-up" style={{ marginTop: '16px', lineHeight: '1.5' }}>
          I always love a challange
        </Typography>
      </Box>
      <Typography variant={isMobile ? "h4" : "h3"} data-aos="fade-up" style={{ marginTop: '32px', fontWeight: 'bold' }}>
        Who am I? 
      </Typography>
      <Typography variant={isMobile ? "h6" : "h5"} data-aos="fade-up" style={{ marginTop: '16px', lineHeight: '1.5' }}>
        I'm a self-taught developer based in <TextColor variant="h5" style={{ display: 'inline' }}><a href="https://en.wikipedia.org/wiki/Scotland" style={{ textDecoration: 'none', color: '#4f46e5', fontWeight: 'bold' }}>Scotland</a></TextColor>. {!isMobile && <br /> } 
        I am currently still in High School. I have done work on multiple projects and have a passion for coding. {!isMobile && <br />}
        Some of these projects are available on my <Link to="/projects" style={{ textDecoration: 'none', color: '#4f46e5', fontWeight: 'bold' }}>Projects</Link> page. {!isMobile && <br />}
        I also have a <Link to="/blog" style={{ textDecoration: 'none', color: '#4f46e5', fontWeight: 'bold' }}>Blog</Link> where I post about my projects etc.
      </Typography>
      <Typography variant="h4" data-aos="fade-up" style={{ marginTop: '32px', fontWeight: 'bold' }}>
        How to Contact me?
      </Typography>
      <Typography variant="h6" data-aos="fade-up" style={{ marginTop: '16px', lineHeight: '1.5' }}>
          You can find me on <a href="https://github.com/kezza2k7" style={{ textDecoration: 'none', color: '#4f46e5', fontWeight: 'bold' }} title="kezza2k7" >GitHub</a> <br />
          Feel free to reach out to me via <a href="mailto:jay@estopia.net" style={{ textDecoration: 'none', color: '#4f46e5', fontWeight: 'bold' }} title="jay@estopia.net" >email</a>
      </Typography>
      <Typography variant="h6" style={{ marginTop: '25px', lineHeight: '1.5' }}>

      </Typography>
    </Container>
  );
}

export default App;
