import React, { useState } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Paper, Button, TextField } from '@mui/material';
import data from '../data/blogs';
import { useMediaQuery } from '@mui/material';

const BlogList = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('newest');

    const onSelect = (blog) => {
        window.location.href = `/blog/${blog.id}`;
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortOrderChange = () => {
        setSortOrder((prevOrder) => (prevOrder === 'newest' ? 'oldest' : 'newest'));
    };

    const filteredBlogs = data.filter((blog) =>
        blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedBlogs = filteredBlogs.sort((a, b) => {
        if (sortOrder === 'newest') {
            return new Date(b.date) - new Date(a.date);
        } else {
            return new Date(a.date) - new Date(b.date);
        }
    });

    return (
        <Container>
            <Typography variant={isMobile ? "h2" : "h1"} component="div" sx={{ textAlign: 'center', marginTop: '64px', fontWeight: '700' }}>
                Blog Posts
            </Typography>
            <TextField
                label="Search Blogs"
                variant="outlined"
                value={searchQuery}
                onChange={handleSearchChange}
                sx={{ marginTop: '16px', marginBottom: '16px', width: '100%' }}
                InputProps={{
                    style: { color: 'white' },
                }}
                InputLabelProps={{
                    style: { color: 'white' },
                }}
            />
            <Button variant="contained" onClick={handleSortOrderChange} sx={{ marginBottom: '32px', backgroundColor: '#4f46e5' }}>
                Sorting by {sortOrder === 'newest' ? 'Newest' : 'Oldest'}
            </Button>
            <List>
                {sortedBlogs.map((blog) => (
                    <Paper key={blog.id} style={{ background: '#121212', marginBottom: 5 }}>
                        <ListItem>
                            <ListItemText
                                primary={<Typography style={{ color: '#FFFFFF' }}>{blog.title}</Typography>}
                                secondary={<Typography style={{ color: '#FFFFFF' }}>{blog.date}</Typography>}
                            />
                            <Button
                                variant="contained"
                                onClick={() => onSelect(blog)}
                                sx={{ background: '#4f46e5', '&:hover': { background: '#4f46e5' } }}
                            >
                                Read More
                            </Button>
                        </ListItem>
                    </Paper>
                ))}
            </List>
        </Container>
    );
};

export default BlogList;
