import React, { useState, useEffect }from 'react';
import { AppBar, Toolbar, Typography, Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';

// Styled components
const StyledAppBar = styled(AppBar)`
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : '#121212')}; // Background color of the header
  backdrop-filter: ${({ transparent }) => (transparent ? 'blur(10px)' : 'none')}; // Blur effect when transparent
  border-bottom: 1px solid #252525; // Border color and width at the bottom
  font-weight: 900; // Boldness of the text
`;

const NavButton = styled(Button)`
  color: #ffffff; // Text color for the buttons
  margin-left: 16px; // Spacing between buttons
  font-family: 'Inter', sans-serif; // Specific font for buttons
  font-weight: 500; // Boldness of the text
  font-size: 20px; // Font size for buttons
  line-height: 23.2px; // Line height for buttons
  text-align: center; // Center align the text

  &:hover {
    color: #4f46e5; // Change color on hover
  }
`;

function Header() {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [transparent, setTransparent] = useState(true); // State to manage header transparency

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setTransparent(true);
      } else {
        setTransparent(false);
      }
    };
  
    // Check scroll position on mount
    handleScroll();
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <StyledAppBar position="fixed" transparent={transparent}>
      <Toolbar>
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            {/* Left Side: Logo/Brand */}
            <Typography variant="h6" component={Link} to="/" sx={{ color: '#ffffff', textDecoration: 'none', fontWeight: 500, fontSize: '25px' }}>
              {isMobile ? 'Jay' : 'Jay | Developer'}
            </Typography>
            {/* Right Side: Navigation Links */}
            <Box>
              <NavButton component={Link} to="/blog">
                Blog
              </NavButton>
              <NavButton component={Link} to="/projects">
                Projects
              </NavButton>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </StyledAppBar>
  );
}

export default Header;
