import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';

const BlogDetail = ({ id }) => {
  const [BlogComponent, setBlogComponent] = useState(null);

  useEffect(() => {
    // Dynamically import the blog post component
    import(`../blogpages/${id}.js`)
      .then((module) => setBlogComponent(() => module.default))
      .catch((error) => {
        console.error('Error loading blog page:', error);
        setBlogComponent(() => () => <div>Error loading blog post.</div>);
      });
  }, [id]);

  if (!BlogComponent) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
        <div className="page-content" style={{marginTop: '128px'}}>
            <Box 
                sx={{ 
                    backgroundColor: '#121212', 
                    border: '1px solid #252525',
                    borderRadius: '16px', 
                    padding: '16px',
                    marginBottom: '32px' 
                }}
            >
                <BlogComponent />
            </Box>
        </div>
    </Container>
    );
};

export default BlogDetail;
